import React from "react";
import ReactDOM from "react-dom";
import WebsiteProvider from "./context/WebsiteContext";
import AuthenticationProvider from "./context/AuthenticationContext";
import "./css/index.scss";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <WebsiteProvider>
        <App />
      </WebsiteProvider>
    </AuthenticationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
