import React, { useEffect, useState } from "react";
export const WebsiteContext = React.createContext();

export const WebsiteProvider = ({ children }) => {
  let [websites, setWebsites] = useState([]);
  let [websiteLength, setWebsiteLength] = useState(null);

  let server_url = "http://localhost/wackenhut4me/httpdocs/php/";
  if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
    server_url = "https://wackenhut4.me/php/";
  }

  useEffect(() => {
    fetch(server_url + "website/get-sites.php")
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setWebsites(data.websites.reverse());
          setWebsiteLength(true);
        } else {
          setWebsiteLength(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [server_url]);

  const counter = (websiteData) => {
    fetch(server_url + "website/counter.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(websiteData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          websites = websites.map((website) => {
            if (website.id === websiteData.id) {
              website.calls = websiteData.calls;
              return website;
            }
            return website;
          });
          setWebsites(websites);
        } else {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createWebsite = (newWebsite) => {
    fetch(server_url + "website/create-site.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newWebsite),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.id) {
          setWebsites([
            {
              id: data.id,
              ...newWebsite,
            },
            ...websites,
          ]);
          setWebsiteLength(true);
        } else {
          alert(data.msg);
          if (data.error) {
            console.log(data.error);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateWebsite = (websiteData) => {
    fetch(server_url + "website/update-site.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(websiteData),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          websites = websites.map((website) => {
            if (website.id === websiteData.id) {
              website.name = websiteData.name;
              website.url = websiteData.url;
              return website;
            }
            return website;
          });
          setWebsites(websites);
        } else {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteWebsite = (theID) => {
    let websiteDeleted = websites.filter((website) => {
      return website.id !== theID;
    });
    fetch(server_url + "website/delete-site.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: theID }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setWebsites(websiteDeleted);
          if (websites.length === 1) {
            setWebsiteLength(0);
          }
        } else {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const value = {
    websites,
    websiteLength,
    counter,
    updateWebsite,
    createWebsite,
    deleteWebsite,
  };

  return <WebsiteContext.Provider value={value}>{children}</WebsiteContext.Provider>;
};

export default WebsiteProvider;
