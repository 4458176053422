import React, { useState, useContext } from "react";
import { WebsiteContext } from "../context/WebsiteContext";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";

function UpdateWebsite({ id, setShowUpdateForm }) {
  const websiteContext = useContext(WebsiteContext);

  let website = websiteContext.websites.find((item) => String(item.id) === String(id));
  const [newWebsite, setNewWebsite] = useState(website);

  const updateNewData = (e, field) => {
    setNewWebsite({
      ...newWebsite,
      [field]: e.target.value,
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    websiteContext.updateWebsite(newWebsite);
    e.target.reset();
    setShowUpdateForm(false);
  };

  return (
    <>
      <div className="centering">
        <div className="overlay">
          <CloseIcon className="close-button" onClick={() => setShowUpdateForm(false)} />

          <h2>Weiterleitung bearbeiten</h2>

          <form onSubmit={saveChanges}>
            <div className="custom-row">
              <div className="custom-col">
                <label htmlFor="name">Name*</label>
                <input
                  id="name"
                  type="text"
                  required
                  defaultValue={website.name}
                  onChange={(e) => updateNewData(e, "name")}
                  placeholder="Wackenhut"
                />
              </div>
            </div>

            <div className="custom-row">
              <div className="custom-col">
                <label htmlFor="url">URL*</label>
                <input
                  id="url"
                  type="text"
                  required
                  defaultValue={website.url}
                  onChange={(e) => updateNewData(e, "url")}
                  placeholder="https://www.wackenhut.de/"
                />
              </div>
            </div>

            <div className="custom-row">
              <button className="form-button" type="submit">
                Speichern
              </button>
              <button className="form-button" onClick={() => setShowUpdateForm(false)}>
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateWebsite;
