import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../context/AuthenticationContext";

function Login() {
  const { loginUser, isLoggedIn } = useContext(AuthenticationContext);

  const initialState = {
    userInfo: {
      email: "",
      password: "",
    },
    errorMsg: "",
    successMsg: "",
  };

  const [state, setState] = useState(initialState);

  // On change input value (email & password)
  const onChangeValue = (e) => {
    setState({
      ...state,
      userInfo: {
        ...state.userInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  // On Submit Login From
  const submitForm = async (event) => {
    event.preventDefault();
    const data = await loginUser(state.userInfo);
    if (data.success && data.token) {
      setState({
        ...initialState,
      });
      localStorage.setItem("loginToken", data.token);
      await isLoggedIn();
    } else {
      setState({
        ...state,
        successMsg: "",
        errorMsg: data.message,
      });
    }
  };

  // Show Message on Error or Success
  let successMsg = "";
  let errorMsg = "";
  if (state.errorMsg) {
    errorMsg = <div className="error-msg">{state.errorMsg}</div>;
  }
  if (state.successMsg) {
    successMsg = <div className="success-msg">{state.successMsg}</div>;
  }

  return (
    <div className="centering">
      <div className="overlay">
        <h2>Login</h2>

        <form onSubmit={submitForm} noValidate>
          <div className="custom-row">
            <div className="custom-col">
              <label>Email</label>
              <input
                name="email"
                type="email"
                required
                placeholder="Bitte geben Sie ihre Email ein."
                value={state.userInfo.email}
                onChange={onChangeValue}
              />
            </div>
          </div>

          <div className="custom-row">
            <div className="custom-col">
              <label>Passwort</label>
              <input
                name="password"
                type="password"
                required
                placeholder="Bitte geben Sie ihr Passwort ein."
                value={state.userInfo.password}
                onChange={onChangeValue}
              />
            </div>
          </div>

          <div className="custom-row">
            <div className="custom-col">
              {errorMsg}
              {successMsg}
            </div>
          </div>

          <div className="custom-row">
            <button className="form-button" type="submit">
              Einloggen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
