import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../context/AuthenticationContext";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";

function Register({ setShowAccountForm }) {
  const { registerUser } = useContext(AuthenticationContext);
  const initialState = {
    userInfo: {
      name: "",
      email: "",
      password: "",
    },
    errorMsg: "",
    successMsg: "",
  };
  const [state, setState] = useState(initialState);

  // On Submit the Registration Form
  const submitForm = async (event) => {
    event.preventDefault();
    const data = await registerUser(state.userInfo);
    if (data.success) {
      setState({
        ...initialState,
        successMsg: data.message,
      });
    } else {
      setState({
        ...state,
        successMsg: "",
        errorMsg: data.message,
      });
    }
  };

  // On change the Input Value (name, email, password)
  const onChangeValue = (e) => {
    setState({
      ...state,
      userInfo: {
        ...state.userInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  // Show Message on Success or Error
  let successMsg = "";
  let errorMsg = "";
  if (state.errorMsg) {
    errorMsg = <div className="error-msg">{state.errorMsg}</div>;
  }
  if (state.successMsg) {
    successMsg = <div className="success-msg">{state.successMsg}</div>;
  }

  return (
    <div className="centering">
      <div className="overlay">
        <CloseIcon className="close-button" onClick={() => setShowAccountForm(false)} />

        <h2>Registrierung</h2>

        <form onSubmit={submitForm} noValidate>
          <div className="custom-row">
            <div className="custom-col">
              <label>Name</label>
              <input
                name="name"
                required
                type="text"
                value={state.userInfo.name}
                onChange={onChangeValue}
                placeholder="Bitte geben Sie ihren Namen ein."
              />
            </div>
          </div>

          <div className="custom-row">
            <div className="custom-col">
              <label>Email</label>
              <input
                name="email"
                required
                type="email"
                value={state.userInfo.email}
                onChange={onChangeValue}
                placeholder="Bitte geben Sie ihre Email ein."
              />
            </div>
          </div>

          <div className="custom-row">
            <div className="custom-col">
              <label>Passwort</label>
              <input
                name="password"
                required
                type="password"
                value={state.userInfo.password}
                onChange={onChangeValue}
                placeholder="Bitte geben Sie ihr Passwort ein."
              />
            </div>
          </div>

          <div className="custom-row">
            <div className="custom-col">
              {errorMsg}
              {successMsg}
            </div>
          </div>

          <div className="custom-row">
            <button className="form-button" type="submit">
              Registrieren
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
