import React from "react";
import { Link } from "react-router-dom";
import "../css/error.scss";

function NotFound() {
  return (
    <div className="error">
      <Link to="/login">Login</Link>
      <div className="background-wrapper">
        <h1 id="visual">404</h1>
      </div>
      <p>Diese Seite, nach der Sie suchen, existiert nicht.</p>
    </div>
  );
}

export default NotFound;
