import React, { useContext } from "react";
import { WebsiteContext } from "../context/WebsiteContext";

function Confirmation({ id, setShowConfirmation }) {
  const websiteContext = useContext(WebsiteContext);

  const deleteConfirm = () => {
    websiteContext.deleteWebsite(id);
    setShowConfirmation(false);
  };

  return (
    <div className="centering">
      <div className="overlay">
        <h2>Wollen Sie diese Weiterleitung wirklich löschen?</h2>

        <p>Alle Daten werden unwiderruflich gelöscht.</p>

        <div className="right">
          <button className="form-button" onClick={() => deleteConfirm()}>
            Löschen
          </button>
          <button className="form-button" onClick={() => setShowConfirmation(false)}>
            Zurück
          </button>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
