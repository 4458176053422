import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
export const AuthenticationContext = createContext();

let server_url = "http://localhost/wackenhut4me/httpdocs/php/";
if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  server_url = "https://wackenhut4.me/php/";
}

// Define the base URL
const Axios = axios.create({
  baseURL: server_url,
});

export const AuthenticationProvider = ({ children }) => {
  let [state, setState] = useState({
    showLogin: true,
    isAuth: false,
    theUser: null,
  });

  useEffect(() => {
    isLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toggle between Login & Signup page
  const toggleNav = () => {
    const showLogin = !state.showLogin;
    setState({
      ...state,
      showLogin,
    });
  };

  // On Click the Log out button
  const logoutUser = () => {
    localStorage.removeItem("loginToken");
    setState({
      ...state,
      isAuth: false,
    });
  };

  const registerUser = async (user) => {
    // Sending the user registration request
    const register = await Axios.post("register.php", {
      name: user.name,
      email: user.email,
      password: user.password,
    });

    return register.data;
  };

  const loginUser = async (user) => {
    // Sending the user Login request
    const login = await Axios.post("login.php", {
      email: user.email,
      password: user.password,
    });
    return login.data;
  };

  // Checking user logged in or not
  const isLoggedIn = async () => {
    const loginToken = localStorage.getItem("loginToken");

    // If inside the local-storage has the JWT token
    if (loginToken) {
      //Adding JWT token to axios default header
      Axios.defaults.headers.common["Authorization"] = "bearer " + loginToken;

      // Fetching the user information
      const { data } = await Axios.get("user-info.php");

      // If user information is successfully received
      if (data.success && data.user) {
        setState({
          ...state,
          isAuth: true,
          theUser: data.user,
        });
      }
    }
  };

  const value = {
    state,
    toggleNav,
    isLoggedIn,
    registerUser,
    loginUser,
    logoutUser,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
