import React from "react";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";

function Website({ items, setShowUpdateForm, setShowConfirmation }) {
  return (
    <>
      {items.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td
              className="clickable"
              onClick={() => {
                window.location.href = item.url;
              }}
            >
              {item.url}
            </td>
            <td>{item.calls}</td>
            <td className="clickable" onClick={() => setShowUpdateForm(item.id)}>
              <EditIcon />
            </td>
            <td className="clickable" onClick={() => setShowConfirmation(item.id)}>
              <TrashIcon />
            </td>
          </tr>
        );
      })}
    </>
  );
}

export default Website;
