import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { AuthenticationContext } from "./context/AuthenticationContext";
import { WebsiteContext } from "./context/WebsiteContext";
import WebsiteOverview from "./components/WebsiteOverview";
import Redirection from "./components/Redirection";
import NotFound from "./components/NotFound";
import Login from "./components/Login";

function App() {
  const { state } = useContext(AuthenticationContext);
  const { isAuth } = state;
  const websiteContext = useContext(WebsiteContext);
  const websites = websiteContext.websites || [];

  return (
    <Router>
      <Switch>
        <Route path="/admin">
          {isAuth ? <WebsiteOverview /> : <Redirect to="/login" />}
        </Route>
        <Route path="/login">{isAuth ? <Redirect to="/admin" /> : <Login />}</Route>
        {websites.map((item) => {
          return (
            <Route
              key={item.id}
              path={"/" + item.name}
              component={() => <Redirection website={item} />}
            />
          );
        })}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
