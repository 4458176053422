import React from "react";
import { ReactComponent as WarningIcon } from "../assets/icons/warning.svg";

const Loading = ({ length }) => {
  return (
    <>
      {length === null ? (
        <div className="loader"></div>
      ) : (
        <div className="warning">
          <WarningIcon />
          Bitte erstellen Sie eine Weiterleitung.
        </div>
      )}
    </>
  );
};

export default Loading;
