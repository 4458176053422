import React, { useState, useEffect, useContext } from "react";
import { WebsiteContext } from "../context/WebsiteContext";
import "../css/redirect.scss";

function Redirection({ website }) {
  const websiteContext = useContext(WebsiteContext);
  const [timeLeft, setTimeLeft] = useState(3);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else if (timeLeft <= 0) {
        websiteContext.counter(website);
        window.location.replace(website.url);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className="redirect">
      <h1>Sie werden in {timeLeft} Sekunden weitergeleitet.</h1>
      <p>Falls Sie nicht weitergeleitet werden benutzen sie folgenden Link:</p>
      <a href={website.url}>{website.url}</a>
    </div>
  );
}

export default Redirection;
