import React, { useContext, useState } from "react";
import { ReactComponent as PlusIcon } from "../assets/icons/plus_fill.svg";
import { AuthenticationContext } from "../context/AuthenticationContext";
import { WebsiteContext } from "../context/WebsiteContext";
import CreateWebsite from "./CreateWebsite";
import UpdateWebsite from "./UpdateWebsite";
import Confirmation from "./Confirmation";
import Loading from "./Loading";
import Website from "./Website";
import Register from "./Register";

function WebsiteOverview() {
  const { logoutUser } = useContext(AuthenticationContext);
  const websiteContext = useContext(WebsiteContext);
  const [showAccountForm, setShowAccountForm] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const websites = websiteContext.websites || [];

  return (
    <>
      <div className="main-container">
        {showConfirmation && (
          <Confirmation id={showConfirmation} setShowConfirmation={setShowConfirmation} />
        )}
        {showAccountForm && <Register setShowAccountForm={setShowAccountForm} />}
        {showCreateForm && <CreateWebsite setShowCreateForm={setShowCreateForm} />}
        {showUpdateForm && (
          <UpdateWebsite id={showUpdateForm} setShowUpdateForm={setShowUpdateForm} />
        )}

        <h1 className="text-center">Weiterleitungs-Übersicht</h1>
        <button className="logout" onClick={logoutUser}>
          Ausloggen
        </button>

        <div className="flex-box">
          <div className="left">
            <div className="create-new">
              <PlusIcon onClick={() => setShowCreateForm(!showCreateForm)} />
              <h5>Neue Weiterleitung erstellen</h5>
            </div>
          </div>

          <div className="middle"></div>

          <div className="right">
            <div className="create-account">
              <h5>Neuen Account erstellen</h5>
              <PlusIcon onClick={() => setShowAccountForm(!showAccountForm)} />
            </div>
          </div>
        </div>

        {!websiteContext.websiteLength ? (
          <Loading length={websiteContext.websiteLength} />
        ) : (
          <>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>URL</th>
                  <th className="small">Aufrufe</th>
                  <th className="small"></th>
                  <th className="small"></th>
                </tr>
              </thead>

              <tbody>
                <Website
                  items={websites}
                  setShowUpdateForm={setShowUpdateForm}
                  setShowConfirmation={setShowConfirmation}
                />
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
}

export default WebsiteOverview;
