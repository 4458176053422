import React, { useContext, useState } from "react";
import { WebsiteContext } from "../context/WebsiteContext";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";

function CreateWebsite({ setShowCreateForm }) {
  const websiteContext = useContext(WebsiteContext);
  const [newWebsite, setNewWebsite] = useState({});

  const addNewWebsite = (e, field) => {
    setNewWebsite({
      ...newWebsite,
      [field]: e.target.value,
    });
  };

  const submitWebsite = (e) => {
    e.preventDefault();
    websiteContext.createWebsite(newWebsite);
    e.target.reset();
    setNewWebsite({});
    setShowCreateForm(false);
  };

  return (
    <div className="centering">
      <div className="overlay">
        <CloseIcon className="close-button" onClick={() => setShowCreateForm(false)} />

        <h2>Neue Weiterleitung</h2>

        <form onSubmit={submitWebsite}>
          <div className="custom-row">
            <div className="custom-col">
              <label htmlFor="name">Name*</label>
              <input
                id="name"
                type="text"
                required
                onChange={(e) => addNewWebsite(e, "name")}
                placeholder="Wackenhut"
              />
            </div>
          </div>

          <div className="custom-row">
            <div className="custom-col">
              <label htmlFor="url">URL*</label>
              <input
                id="url"
                type="text"
                required
                onChange={(e) => addNewWebsite(e, "url")}
                placeholder="https://www.wackenhut.de/"
              />
            </div>
          </div>

          <div className="custom-row">
            <button className="form-button" type="submit">
              Weiterleitung erstellen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateWebsite;
